<template>
	<div
		:class="['counter-wrapper', size]"
		id="counter"
	>
		<TnButton
			@click="subtract"
			iconOnly="minus"
			aria-label="minus"
			:secondary="secondary"
			:tertiary="tertiary"
			:size="size"
			:disabled="!subtractButtonEnabled || subtractDisabled"
			:dark="dark"
		/>

		<span
			tabindex="0"
			role="slider"
			:class="{ dark }"
			:aria-label="numSelected"
			:aria-valuemax="maxAmount"
			:aria-valuemin="startValue || 0"
			:aria-valuenow="numSelected"
			@keyup="keymonitor"
			>{{ numSelected }}</span
		>

		<TnButton
			@click="add"
			iconOnly="plus"
			aria-label="plus"
			:secondary="secondary"
			:tertiary="tertiary"
			:size="size"
			:disabled="!addButtonEnabled || addDisabled"
			:dark="dark"
		/>
	</div>
</template>

<script>
import sizes from "./definitions/sizes";

/**
 * Counter component for incrementing / decrementing value
 * @displayName TnCounter
 */
export default defineComponent({
	name: "TnCounter",

	props: {
		/**
		 * Disables the "add" button
		 */
		addDisabled: {
			type: Boolean,
			default: false,
		},
		/**
		 * Disables the "subtract" button
		 */
		subtractDisabled: {
			type: Boolean,
			default: false,
		},
		/**
		 * Set secondary button state
		 */
		secondary: {
			type: Boolean,
			default: false,
		},
		/**
		 * Set tertiary button state
		 */
		tertiary: {
			type: Boolean,
			default: false,
		},
		/**
		 * Set size of the buttons & text
		 */
		size: {
			type: String,
			default: "m",
			validator: function (value) {
				return sizes.includes(value.toLowerCase());
			},
		},
		/**
		 * Set max increment value
		 */
		maxAmount: {
			type: Number,
		},
		/**
		 * Set start value (default 0)
		 */
		startValue: {
			type: Number,
			default: 0,
		},
		/**
		 * Dark theming
		 */
		dark: {
			type: Boolean,
			default: false,
		},
	},

	emits: [
		/**
		 * Emits current value upon change
		 */
		"input",
		/**
		 * Event emitted upon incrementing
		 */
		"add",
		/**
		 * Event emitted upon decrementing
		 */
		"subtract",
		/**
		 * Event emitted upon change
		 */
		"update:modelValue",
	],

	data() {
		return {
			numSelected: 0,
			disabledAdd: false,
			disabledSubtract: false,
		};
	},

	computed: {
		addButtonEnabled() {
			return this.numSelected < this.maxAmount || this.maxAmount == null;
		},
		subtractButtonEnabled() {
			return this.numSelected > 0;
		},
	},

	mounted() {
		this.getValue();
	},

	methods: {
		emitNumSelected() {
			this.$emit("update:modelValue", this.numSelected);
		},
		add() {
			this.numSelected++;
			this.emitNumSelected();
			this.$emit("add");
		},
		subtract() {
			this.numSelected--;
			this.emitNumSelected();
			this.$emit("subtract");
		},
		keymonitor(event) {
			if (event.key === "ArrowUp" && this.addButtonEnabled) {
				this.add();
			}
			if (event.key === "ArrowDown" && this.subtractButtonEnabled) {
				this.subtract();
			}
		},
		getValue() {
			if (this.startValue) {
				this.numSelected = this.startValue;
			}
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/typography/scss/placeholders";
@use "@/assets/scss/variables" as variables;

.counter-wrapper {
	display: inline-flex;
	justify-content: space-between;
	gap: variables.$spacing-m;
	place-items: center center;

	span.dark {
		color: variables.$color-neutrals-white;
	}

	&.xs {
		gap: variables.$spacing-m;

		@extend %font-title-s;
	}

	&.s {
		gap: variables.$spacing-m;

		@extend %font-title-m;
	}

	&.m {
		gap: variables.$spacing-m;

		@extend %font-title-l;
	}

	&.l {
		gap: variables.$spacing-l;

		@extend %font-title-l;
	}
}

:deep(button.l) {
	svg {
		width: 40px;
		height: 40px;
	}
}
</style>
